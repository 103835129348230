import React from "react"

import {
  CTA,
  Description,
  Heading,
  Kicker,
  MediaSide,
  SuccessItemWrapper,
} from "../styles/SuccessItem.styled"
import formatSuccessItem from "../utils/formatSuccessItem"

import SuccessMediaSide from "./SuccessMediaSide"

const SuccessItem = ({
  item,
  activeSlide,
  slideIndex,
  playing,
  setPlaying,
}) => {
  const { kickerText, slug, ctaText } = formatSuccessItem(item)

  return (
    <SuccessItemWrapper>
      {activeSlide === slideIndex && (
        <>
          <div>
            <Kicker>{kickerText}</Kicker>
            <Heading>{item?.heading}</Heading>
            <Description>{item?.description}</Description>
            <CTA to={slug}>{ctaText}</CTA>
          </div>
          <MediaSide type={item?.referenceType}>
            <SuccessMediaSide
              type={item?.referenceType}
              image={item?.image}
              video={item?.videoLink}
              videoThumbnail={item?.videoThumbnail}
              icon={item?.icon}
              customer={item?.customer}
              quote={item?.quote}
              author={item?.author}
              playing={playing}
              setPlaying={setPlaying}
            />
          </MediaSide>
        </>
      )}
    </SuccessItemWrapper>
  )
}

export default SuccessItem
