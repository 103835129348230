import React from "react"
import ReactPlayer from "react-player"

import OptimizedImage from "../../../utils/OptimizedImage"
import PlayButton from "../../Video/components/PlayButton"
import {
  ImageWrapper,
  QuoteWrapper,
  Testimonial,
  PersonWrapper,
  PersonText,
  Name,
  Role,
  VideoWrapper,
  Overlay,
} from "../styles/SuccessMediaSide.styled"

const SuccessMediaSide = ({
  type,
  image,
  video,
  videoThumbnail,
  quote,
  customer,
  author,
  icon,
  playing,
  setPlaying,
}) => {
  switch (type) {
    case "Quote":
      return (
        <QuoteWrapper>
          {icon && (
            <OptimizedImage
              className="icon"
              image={icon?.gatsbyImageData}
              src={icon?.url}
              alt={`${customer} logo`}
            />
          )}
          {quote && <Testimonial>&rdquo;{quote}&rdquo;</Testimonial>}
          {author && author?.headshot && author?.fullName && author?.role && (
            <PersonWrapper>
              <OptimizedImage
                className="headshot"
                image={author?.headshot?.gatsbyImageData}
                src={author?.headshot?.url}
                alt={`${author.fullName} headshot`}
              />
              <PersonText>
                <Name>{author?.fullName}</Name>
                <Role>{author?.role}</Role>
              </PersonText>
            </PersonWrapper>
          )}
        </QuoteWrapper>
      )
    case "Video":
      return (
        <VideoWrapper>
          {video && (
            <ReactPlayer
              className="react-player"
              controls={true}
              playing={playing}
              light={videoThumbnail?.url}
              playIcon={<PlayButton setPlaying={setPlaying} />}
              pip={false}
              height="100%"
              width="100%"
              url={video.trim()}
            />
          )}
        </VideoWrapper>
      )
    case "Image":
      return (
        <ImageWrapper>
          {image && (
            <>
              <OptimizedImage
                image={
                  image?.desktop?.gatsbyImageData || image?.gatsbyImageData
                }
                src={image?.url}
              />
              <Overlay />
            </>
          )}
        </ImageWrapper>
      )
    default:
      return null
  }
}

export default SuccessMediaSide
