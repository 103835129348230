import styled, { css } from "styled-components"

import { color } from "../../../atoms/colors"

export const CustomerSuccessWrapper = styled.section`
  padding: 40px 30px;
  overflow-x: visible;
  overflow-y: hidden;
  @media (min-width: 577px) {
    padding: 56px 30px;
  }
  @media (min-width: 993px) {
    padding: 96px 30px 132px;
  }
`

export const Content = styled.div`
  max-width: 1170px;
  margin: 0 auto;
`

export const HeadingWrapper = styled.div`
  text-align: center;
  margin: 0 auto;
  max-width: 770px;
`

export const Heading = styled.h2`
  font-weight: 900;
  letter-spacing: -0.02em;
  color: ${color.oxfordBlue[900]};
  margin-bottom: 16px;
  ${(props) =>
    (props?.size &&
      props?.size === "heading-1" &&
      css`
        font-size: 24px;
        line-height: 32px;
        @media (min-width: 577px) {
          font-size: 36px;
          line-height: 44px;
        }
        @media (min-width: 993px) {
          font-size: 48px;
          line-height: 60px;
        }
      `) ||
    (props?.size === "heading-2" &&
      css`
        font-size: 24px;
        line-height: 32px;
        @media (min-width: 577px) {
          font-size: 30px;
          line-height: 38px;
        }
        @media (min-width: 993px) {
          font-size: 36px;
          line-height: 44px;
        }
      `)}
`

export const Subheading = styled.p`
  font-weight: 350;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${color.copy[700]};
  margin-bottom: 0;
  @media (min-width: 993px) {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
  }
`

export const NavWrapper = styled.div`
  display: grid;
  border-bottom: 2px solid ${color.copy[200]};
  margin: 40px -30px;
  @media (min-width: 577px) {
    margin: 56px -30;
  }
  @media (min-width: 993px) {
    margin: 64px 0;
  }
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  ${(props) =>
    props?.num &&
    css`
      grid-template-columns: repeat(${props?.num}, 1fr);
      ::-webkit-scrollbar {
        display: none;
      }
    `}
`

export const NavItem = styled.div`
  height: 60px;
  min-width: 160px;
  position: relative;
  .gatsby-image-wrapper {
    img {
      max-height: 44px !important;
      width: auto;
    }
  }
  ${(props) =>
    !props?.active &&
    css`
      img {
        filter: grayscale(100%);
        -webkit-filter: grayscale(100%);
      }
    `}
`

export const Progress = styled.div`
  position: absolute;
  bottom: 0px;
  ${(props) =>
    props?.active &&
    css`
      animation: progressBar 10s linear forwards;
      background-color: ${color.primary[700]};
      height: 4px;
      @keyframes progressBar {
        0% {
          width: 0%;
        }
        100% {
          width: 100%;
        }
      }
    `}
`

export const ImageWrap = styled.div`
  margin: 0 auto;
  width: fit-content;
`
