const formatSuccessItem = (item) => {
  let kickerText
  let ctaText
  let slug

  switch (item?.resource?.model?.apiKey) {
    case "template_ebook":
      kickerText = "eBook"
      ctaText = "Read eBook"
      slug = `/ebooks/${item?.resource?.category?.slug}/${item?.resource?.slug}`
      break
    case "template_article":
      kickerText = "Article"
      ctaText = "Read Article"
      slug = `/articles/${item?.resource?.category?.slug}/${item?.resource?.slug}`
      break
    case "template_blog_post":
      kickerText = "Blog Post"
      ctaText = "Read Blog"
      slug = `/blog/${item?.resource?.slug}`
      break
    case "template_case_study":
      kickerText = "Case Study"
      ctaText = "Read Case Study"
      slug = `/case-studies/${item?.resource?.slug}`
      break
    case "template_infographic":
      kickerText = "Infographic"
      ctaText = "Read Infographic"
      slug = `/infographic/${item?.resource?.category?.slug}/${item?.resource?.slug}`
      break
    case "template_media_post":
      kickerText = "News"
      ctaText = "Learn More"
      slug = item?.resource?.url
      break
    case "template_press_release":
      kickerText = "Press Release"
      ctaText = "Read Press Release"
      slug = `/press-center/${item?.resource?.slug}`
      break
    case "template_video":
      kickerText = "Video"
      ctaText = "Watch Video"
      slug = `/videos/${item?.resource?.category?.slug}/${item?.resource?.slug}`
      break
    case "template_webinar":
      kickerText = "Webinar"
      ctaText = "Watch Webinar"
      slug = `/webinars/${item?.resource?.category?.slug}/${item?.resource?.slug}`
      break
    case "template_white_paper":
      kickerText = "White Paper"
      ctaText = "Read White Paper"
      slug = `/whitepapers/${item?.resource?.category?.slug}/${item?.resource?.slug}`
      break
    default:
      kickerText = "Success Story"
      ctaText = "Learn More"
      slug = null
      return kickerText, ctaText, slug
  }
  return { kickerText: kickerText, ctaText: ctaText, slug: slug }
}

export default formatSuccessItem
