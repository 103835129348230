import React, { useState, useEffect } from "react"

import OptimizedImage from "../../utils/OptimizedImage"
import onKeyDown from "../../utils/onKeyDown"

import SuccessItem from "./components/CustomerSuccessItem"
import {
  CustomerSuccessWrapper,
  Content,
  HeadingWrapper,
  Heading,
  Subheading,
  NavWrapper,
  NavItem,
  ImageWrap,
  Progress,
} from "./styles/CustomerSuccess.styled"

const CustomerSuccess = ({ component }) => {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0)
  const [playing, setPlaying] = useState(false)
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    if (component?.customerSuccessLinks && playing == false) {
      const timer = setInterval(() => {
        setProgress((progress) => {
          if (progress === 100) {
            setActiveSlideIndex(
              activeSlideIndex !== component?.customerSuccessLinks?.length - 1
                ? activeSlideIndex + 1
                : 0
            )

            return 0
          }

          return Math.min(progress + 1, 100)
        })
      }, 100)

      return () => {
        clearInterval(timer)
      }
    }
  }, [activeSlideIndex, playing, progress])

  useEffect(() => {
    setProgress(0)
    setPlaying(false)
  }, [activeSlideIndex])

  useEffect(() => {
    setProgress(0)
  }, [playing])

  const headingSize = component?.headingSize
    ? component?.headingSize.replace(/\s/g, "-").toLowerCase()
    : "heading-1"

  return (
    <CustomerSuccessWrapper>
      <Content>
        <HeadingWrapper>
          {component?.heading && (
            <Heading size={headingSize}>{component?.heading}</Heading>
          )}
          {component?.subheading && (
            <Subheading>{component?.subheading}</Subheading>
          )}
        </HeadingWrapper>
        <NavWrapper num={component?.customerSuccessLinks?.length}>
          {component?.customerSuccessLinks &&
            component?.customerSuccessLinks.map((item, index) => (
              <NavItem
                active={activeSlideIndex === index}
                key={item?.id + "image"}
              >
                <ImageWrap
                  onClick={() => setActiveSlideIndex(index)}
                  onKeyDown={(e) =>
                    onKeyDown(e, () => setActiveSlideIndex(index))
                  }
                  role="button"
                  aria-selected={activeSlideIndex === index}
                  aria-label={`Click to select the ${item?.customer} tab.`}
                  tabIndex={0}
                >
                  <OptimizedImage
                    image={item?.icon?.gatsbyImageData}
                    src={item?.icon?.url}
                    alt={`${item?.customer} logo`}
                  />
                </ImageWrap>
                <Progress active={activeSlideIndex === index} />
              </NavItem>
            ))}
        </NavWrapper>
        {component?.customerSuccessLinks &&
          component?.customerSuccessLinks.map((item, index) => (
            <SuccessItem
              key={item?.id}
              item={item}
              activeSlide={activeSlideIndex}
              slideIndex={index}
              playing={playing}
              setPlaying={setPlaying}
              setProgress={setProgress}
            />
          ))}
      </Content>
    </CustomerSuccessWrapper>
  )
}

export default CustomerSuccess
