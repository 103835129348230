import styled from "styled-components"

import { color } from "../../../atoms/colors"

export const QuoteWrapper = styled.div`
  margin: auto;
  height: fit-content;
  border-radius: 40px 40px 40px 0;
  background: ${color.daveysGray[50]};
  box-shadow: 7px 7px 0 ${color.secondary[400]};
  padding: 40px;
  .icon {
    max-width: 90px;
    margin-bottom: 22px;
  }
`

export const Testimonial = styled.p`
  font-weight: 850;
  font-size: 18px;
  line-height: 24px;
  color: ${color.oxfordBlue[900]};
  margin-bottom: 24px;
  @media (min-width: 577px) {
    font-size: 20px;
    line-height: 30px;
  }
`

export const PersonWrapper = styled.div`
  display: grid;
  grid-template-columns: 48px 1fr;
  grid-column-gap: 16px;
  .headshot {
    margin: auto 0;
    width: 48px;
    height: 48px;
    object-fit: cover;
    border: 1px solid ${color.copy[200]};
    border-radius: 50%;
    img {
      border-radius: 50%;
    }
  }
`

export const PersonText = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: ${color.copy[700]};
  margin: auto 0;
  p {
    margin-bottom: 0;
  }
`

export const Name = styled.p`
  font-weight: 800;
`

export const Role = styled.p`
  font-weight: 350;
`

export const VideoWrapper = styled.div`
  position: relative;
  aspect-ratio: 16 / 9;
  max-height: 100%;
  max-width: 100%;
  margin: 0 auto;
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
  @media (min-width: 993px) {
    width: 100%;
    margin: auto 0;
  }
`

export const ImageWrapper = styled.div`
  margin-bottom: -132px;
  height: 100%;
  overflow: visible;
`

export const Overlay = styled.div`
  position: absolute;
  height: 64px;
  bottom: -40px;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    ${color.common.white} 100%
  );
  @media (min-width: 577px) {
    bottom: -56px;
  }
  @media (min-width: 993px) {
    width: 50%;
    bottom: -132px;
  }
`
