import styled from "styled-components"

import { color } from "../../../atoms/colors"

export const Button = styled.button`
  position: relative;
  border: none;
  height: 64px;
  width: 64px;
  border-radius: 50%;
  background: ${color.primary[200]};
  img {
    margin: auto;
    position: absolute;
    top: 0;
    left: 6px;
    bottom: 0;
    right: 0;
  }
`
