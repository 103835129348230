import { Link } from "gatsby"
import styled, { css } from "styled-components"

import { color } from "../../../atoms/colors"

export const SuccessItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 40px;
  position: relative;
  @media (min-width: 993px) {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 8%;
  }
`

export const Kicker = styled.p`
  font-weight: 800;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  color: ${color.secondary[900]};
  margin-bottom: 16px;
  @media (min-width: 577px) {
    font-size: 16px;
    line-height: 24px;
  }
  @media (min-width: 993px) {
    margin-bottom: 24px;
  }
`

export const Heading = styled.h3`
  font-weight: 800;
  font-size: 20px;
  line-height: 30px;
  color: ${color.oxfordBlue[900]};
  margin-bottom: 16px;
  @media (min-width: 577px) {
    font-size: 24px;
    line-height: 32px;
  }
  @media (min-width: 993px) {
    font-size: 36px;
    line-height: 44px;
    font-weight: 900;
    margin-bottom: 24px;
  }
`

export const Description = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${color.copy[700]};
  margin-bottom: 24px;
  @media (min-width: 993px) {
    font-size: 18px;
    margin-bottom: 40px;
  }
`

export const CTA = styled(Link)`
  position: relative;
  text-decoration: none;
  font-weight: 700;
  padding-right: 15px;
  font-size: 16px;
  top: 8px;
  cursor: pointer;
  color: ${color.primary[700]};
  &:hover {
    color: ${color.primary[200]};
  }
  &:before,
  &:after {
    border-right: 2px solid;
    content: "";
    display: block;
    height: 8px;
    margin-top: -6px;
    position: absolute;
    transform: rotate(135deg);
    right: 0;
    top: 54%;
    width: 0;
  }
  :after {
    margin-top: -1px;
    transform: rotate(45deg);
  }
  @media (min-width: 577px) {
    font-size: 18px;
  }
`

export const MediaSide = styled.div`
  width: 100%;
  margin: auto;
  ${(props) =>
    props?.type &&
    props?.type === "Image" &&
    css`
      max-height: 500px;
      @media (min-width: 993px) {
        max-height: 400px;
      }
    `}
  @media (min-width: 993px) {
    display: flex;
  }
`
