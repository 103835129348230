import React from "react"

import playIcon from "../../../images/PlaySolid.svg"
import { Button } from "../styles/PlayButton.styled"

const PlayButton = ({ setPlaying }) => (
  <Button onClick={() => setPlaying(true)}>
    <img src={playIcon} alt="Play" />
  </Button>
)

export default PlayButton
